<template>
  <div>
    <section id="top-bar">
      <div class="container">
        <div class="row">
          <div class="col">Berikut ini berbagai manfaat mendaftar di SIBI</div>
          <div class="col">
            <div class="text-right bread">
              <i class="fas fa-home"></i> Beranda &gt; Kebijakan
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="content" class="mt-4">
      <div class="container">
        <h2>Manfaat</h2>
        <p></p>
        <ul>
          <li>
            <b>Resmi Terdaftar</b>, Dengan mendaftar di platform SIBI,
            teman-teman pelaku perbukuan mendapat peluang untuk dikenali dan
            dikurasi oleh staff perbukuan, serta dipublikasi sebagai partner
            resmi Kemendikdasmen
          </li>
          <li>
            <b>Hasil Penilaian</b>, Memantau hasil penilaian buku terbaru dan
            terkurasi secara akurat. Informasi tersebut mendapat dimanfaatkan
            untuk pengadaan buku dalam ruang lingkup dana BOS.
          </li>
          <li>
            <b>Pembinaan / Pelatihan</b>, Berpeluang mendapatkan informasi
            pembinaan terbaru serta menjadi orang yang terdepan dalam
            pendaftaran pelatihan. Terus tingkatkan skill terkait perbukuan.
          </li>
          <li>
            <b>Informasi Kebijakan</b>, Mendapatkan informasi kebijakan dan
            pengumuman terkait kegiatan perbukuan.
          </li>
          <li>
            <b>Unduh PDF</b>, Mendapatkan akses untuk mengunduh versi cetak dari
            buku yang diterbitkan Kemendikdasmen
          </li>
          <li>
            <b>Notifikasi</b>, Menjadi orang yang terdepan dalam mendapatkan
            informasi terkait perbukuan.
          </li>
          <li>
            <b>Sertifikasi</b>, Mendapat peluang untuk mengikuti program
            sertifikasi secara online.
          </li>
        </ul>
        <p></p>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>
